import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // esegui playWithRecaptcha() quando grecaptcha è caricato
    // ogni 2 secondi verifica se la variabile grecaptcha è definita
    // se sì, esegui playWithRecaptcha()
    // se no, riprova tra 2 secondi
    const interval = setInterval(() => {
      if (typeof grecaptcha !== 'undefined') {
        clearInterval(interval)
        this.getTokenFromGoogle()

        // quando scade il token, lo rinnovo
        setInterval(() => {
          this.getTokenFromGoogle()
        }, 120000)
      }
    }, 2000)


    this.setupListener()

  }

  // preparo un listener che intercetta tutte le richieste POST
  // e prima di inviarle, aggiunge il token di recaptcha
  setupListener(){
    document.addEventListener('turbo:before-fetch-request', async (event) => {
      if (["POST", "PATCH", "PUT"].includes(event.detail.fetchOptions.method)) {
        event.preventDefault();
        try {
          this.getTokenFromGoogle(); // attendi il token PRIMA di continuare
          if (!this.token) {
            console.error("Errore: il token è undefined, annullo la richiesta.");
            return;
          }
          const body = new URLSearchParams(event.detail.fetchOptions.body);
          body.append("g-recaptcha-response", this.token);
          event.detail.fetchOptions.body = body;
          event.detail.resume();
        } catch (error) {
          console.error("Errore durante la generazione del token:", error);
        }
      } else {
        console.log("Richiesta senza reCAPTCHA:", event.detail.fetchOptions.method);
      }
    });
  }

  getTokenFromGoogle(){
    const _this = this
    grecaptcha.execute('6LcCcjQpAAAAAF95aLbbMvTtIgmxbZCXaGXDrfwn', {action: 'submit'}).then(function(token) {
      _this.token = token
    })
  }
}