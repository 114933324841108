import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// import bootstrap modal
import 'bootstrap/js/dist/modal'

const application = Application.start()
const controllers = import.meta.globEager('./../**/*_controller.js')
registerControllers(application, controllers)

import * as Turbo from '@hotwired/turbo'
Turbo.start()

document.addEventListener('turbo:frame-load', () => {
  // TODO questo va spostato nel js di area admin (magari anche in un controller)

  const selectAll = document.getElementById("user-checkbox-select-all")
  if (selectAll != null){
    const checkboxes = document.getElementsByClassName('user-checkbox-for-deletion')
    if (checkboxes != null && checkboxes.length > 0){
      selectAll.addEventListener('change', (event) => {
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].checked = event.target.checked;
        }
      });
      for (let i = 0; i < checkboxes.length; i++) {
        checkboxes[i].addEventListener('change', (event) => {
          if(!event.target.checked){
            selectAll.checked = false
          }
        })
      }

    }
  }
})

document.addEventListener('turbo:frame-load', () => {

  // TODO questo va spostato nel js di area admin (magari anche in un controller)
  const deletionForm = document.getElementById("users-deletion-form")
  if (deletionForm !== null) {
    deletionForm.addEventListener('submit', async (event) => {
      event.preventDefault();
      const checkboxes = document.getElementsByClassName('user-checkbox-for-deletion')
      let users_to_delete = []
      for (let i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          users_to_delete.push(checkboxes[i].id)
        }
      }
      try {
        const response = await fetch("/admin_area/deletion_users.json", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
          },
          body: JSON.stringify({ "users_to_delete": users_to_delete }),
        });
      } catch (error) {
        console.error(`Error:`, error);
      }
    });
  }
})

document.addEventListener('turbo:frame-load', () => {

  document.querySelectorAll('[tippy]').forEach(element => {
    const content = element.getAttribute('tippy')
    tippy(element, {
      content: content,
      interactive: true,
      allowHTML: true,
      trigger: 'click',
      onShow: () => {
        //mixpanel.track('tooltip', { content: content })
      }
    });
  });
})


document.addEventListener('turbo:submit-start', (event) => {

  // disable submit button
  var nodeToDisable = event.target.querySelector('a')
  if (nodeToDisable) {
    nodeToDisable.style.pointerEvents = 'none'
    nodeToDisable.style.cursor = 'wait'
    // replace with custom loader
    nodeToDisable.innerHTML = '🐲 caricamento...'
  }

})