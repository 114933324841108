import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["title", "artist", "video", "videoImg1", "videoImg2", "videoImg3", "title1", "title2", "title3"];

  connect() {
    this.urls = ["", "", ""];
  }

  async fetchVideoUrl() {
    const title = this.titleTarget.value;
    const artistId = this.artistTarget.value;

    if (title && artistId) {
      try {
        const response = await fetch(`/search_youtube_video?title=${encodeURIComponent(title)}&artist=${encodeURIComponent(artistId)}`);
        
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          if (data.video_url_1 && data.video_url_2 && data.video_url_3) {
            this.videoTarget.value = data.video_url_1;
            this.urls[0] = data.video_url_1;
            this.urls[1] = data.video_url_2;
            this.urls[2] = data.video_url_3;
            this.removeClass1AddClass2(this.videoImg1Target, 'selectable-video', 'selected-video');
            this.removeClass1AddClass2(this.videoImg2Target, 'selected-video', 'selectable-video');
            this.removeClass1AddClass2(this.videoImg3Target, 'selected-video', 'selectable-video');
            this.videoImg1Target.src = data.video_image_1;
            this.videoImg2Target.src = data.video_image_2;
            this.videoImg3Target.src = data.video_image_3;
            this.title1Target.innerText = data.video_title_1;
            this.title2Target.innerText = data.video_title_2;
            this.title3Target.innerText = data.video_title_3;
          } else {
            this.videoTarget.value = '';
          }
        } else {
          console.error('Server response was not OK:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching video URL:', error);
      }
    }
  }

  changeSelectedVideo(event) {
    const index = event.currentTarget.dataset.index; 

    const title1 = this.title1Target;
    const title2 = this.title2Target;
    const title3 = this.title3Target;

    if (title1 && title2 && title3) {
      const image1 = this.videoImg1Target;
      const image2 = this.videoImg2Target;
      const image3 = this.videoImg3Target;
      const images = [image1, image2, image3];
      
      images.forEach((image, i) => {
        if (i + 1 == index) { 
          if (image.classList.contains('selectable-video')) {
            image.classList.remove('selectable-video');
            image.classList.add('selected-video');
            this.videoTarget.value = this.urls[i];
          }
        } else {
          if (image.classList.contains('selected-video')) {
            image.classList.remove('selected-video');
            image.classList.add('selectable-video');
          }
        }
      });
    }
  }

  removeClass1AddClass2(element1, class1, class2) {
    if (element1.classList.contains(class1)) {
      element1.classList.remove(class1);
      element1.classList.add(class2);
    }
  }
}