import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
export default class extends Controller {
  static targets = [ "select", "errors" ]
  static values = {
    urlCreation: String,
    objectToCreate: String
  }
  
  connect() {
    const select = this.selectTarget;

    new TomSelect(select, {
      create: async (input, callback) => {
        try {
          const response = await fetch(this.urlCreationValue, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content,
            },
            body: JSON.stringify({ [this.objectToCreateValue]: { name: input } }),
          });
          const item = await response.json();
          callback({ value: item.id.toString(), text: item.name });
        } catch (error) {
          console.error(`Error creating ${this.objectToCreateValue}:`, error);
          const errors = this.errorsTarget;
          errors.classList.remove('d-none');
          errors.querySelector('ul').appendChild(document.createElement('li')).textContent = error;
          // ritorno null perché la funziona asincrona deve tornare qualcosa per poter sapere di aver terminato l'esecuzione
          // in questo modo riesco a notificare tom select dell'errore durante il tentativo di creazione
          // e tom select non rimane appeso in attesa del nulla.
          // P.S.= callback è una funzione che viene riempita da Tom Select, se ha un argomento lo aggiunge alla lista, 
          // altrimenti capisce che c'è stato un errore e non aggiunge nulla.
          callback(null);
        }
      }
    });
  }
}